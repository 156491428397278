;(function (w, d) {
    'use strict';

    w.TlumVote = w.TlumVote || [];

    function escapeHtml(html) {
        return d.createElement('a')
            .appendChild(d.createTextNode(html))
            .parentNode.innerHTML;
    }

    function addScript(src) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.charset = 'utf-8';

        document.getElementsByTagName('head')[0].appendChild(script);
    }

    class Template {
        constructor(templateString) {
            this._templateString = templateString;
        }

        render(params) {
            return this._templateString
                    .replace(/<%=(.+?)%>/g, (whole, key) => {
                    return params[key.trim()];
        })
        .replace(/<%-(.+?)%>/g, (whole, key) => {
                return escapeHtml(params[key.trim()]);
        })
            ;
        }
    }

    class Api {
        constructor(endpoint, user) {
            this.endpoint = endpoint;
            this.user = user;
        }

        info(callback) {
            return this._query('get', this.endpoint, null, callback);
        }

        vote(formData, callback) {
            return this._query('post', this.endpoint, formData, callback);
        }

        _isAuthorized() {
            return Boolean(this.user.apiKey);
        }

        _query(method, url, formData, callback) {
            var req = new XMLHttpRequest();
            req.open(method, url);
            req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

            // user hash
            if (this.user.hash) {
                req.setRequestHeader('X-VoteUserHash', this.user.hash);
            }

            // user apiKey
            if (this.user.apiKey) {
                req.setRequestHeader('X-ApiKey', this.user.apiKey);
            }

            // resolve
            req.onload = () => {
                if (req.status === 200) {
                    let response = JSON.parse(String(req.response));

                    if (response.code === 200) {
                        if (!this._isAuthorized()) {
                            this.user.setHash(response.user.hash);
                        }

                        callback(response);
                    } else {
                        new Error(response.errors.join('\n'));
                    }
                } else {
                    new Error(req.statusText);
                }
            };

            // reject
            req.onerror = () => {
                new Error('Network Error');
            };

            req.send(formData);
        }
    }

    class KeyValueStorage {
        constructor() {
            this.prefix = 'tlumVote';
        }

        set(key, value) {
            localStorage[`${this.prefix}.${key}`] = value;
        }

        get(key) {
            return localStorage[`${this.prefix}.${key}`]
        }
    }

    class User {
        constructor() {
            this.storage = new KeyValueStorage();
            this.hash = null;
            this.apiKey = null;
            this.findApiKey();
            this.restore();
        }

        findApiKey() {
            if (typeof USER_API_KEY !== 'undefined') {
                this.apiKey = USER_API_KEY;
            }
        }

        restore() {
            this.hash = this.storage.get('userHash');
        }

        save() {
            this.storage.set('userHash', this.hash);
        }

        setHash(hash) {
            this.hash = hash;
            this.save();
        }
    }

    class Url {
        constructor(url, query) {
            this.url = url;
            this.query = query;
        }

        querystring() {
            var result = [];

            for (var i in this.query) {
                if (this.query.hasOwnProperty(i) && this.query[i] !== null) {
                    result.push(encodeURIComponent(i) + '=' + encodeURIComponent(this.query[i]));
                }
            }

            return result.join('&');
        }

        toString() {
            return this.url + '?' + this.querystring();
        }
    }

    class Vote {
        constructor(options) {
            this.options = options;
            this._createEl();

            this.user = new User();
            this.api = new Api(this._apiEndpoint(), this.user);

            //noinspection CssUnknownTarget
            this.tmplMainImage = new Template(`
                <div class="tlum-vote__main-image"
                     title="<%- alt %>"
                     style="background-image: url(<%= src %>)"
                ></div>
            `);

            this._init();
            this._events();
        }

        _apiEndpoint() {
            var route = this.options.host + (this.options.endpoint || '/api/v1/vote/:id/');

            return route.replace(':id', this.options.id);
        }

        _createEl() {
            this.el = d.createElement('div');
            this.el.classList.add('tlum-vote');
            this.options.el.parentNode.insertBefore(this.el, this.options.el);
        }

        _init() {
            this.el.addEventListener('submit', (event) => {
                event.stopPropagation();
            event.preventDefault();

            this.api.vote(this._getFormData(), this._render.bind(this));
        });

            this.api.info(this._render.bind(this));

            addScript('//yastatic.net/share2/share.js');
        }

        _events() {
            w.addEventListener('resize', this._detectSizeBreakpoints.bind(this));
        }

        _getFormData() {
            var form = this.el.getElementsByTagName('form')[0];

            return new FormData(form);
        }

        _render(response) {
            var that = this;

            this.el.innerHTML = new Template(`
                <div class="tlum-vote__container v3__tlum-vote__container">
                    <div class="tlum-vote__header v3__tlum-vote__header">
                        <div class="tlum-vote__header v3__tlum-vote__header">
                            <div class="tlum-vote__text v3__tlum-vote__text">
                                <%- header %>
                            </div>
                        </div>
                    </div>
                    <div class="tlum-vote__body">
                        <%= content %>
                    </div>
                </div>
            `).render({
                header: response.vote.text,
                content: (response.voted === false) ? this._renderForm(response) : this._renderResult(response),
            });

            this._detectSizeBreakpoints();

            try {
                setTimeout(function () {
                    var shareEl = that.el.getElementsByClassName('js-tlum-vote-share')[0];
                    Ya.share2(shareEl);
                }, 0);
            } catch (e) {}
        }

        _detectSizeBreakpoints() {
            var classPrefix = 'tlum-vote__size-';
            var breakpoints = [280, 500];
            var width = this.el.offsetWidth;

            breakpoints.forEach((breakpoint) => {
                if (width >= breakpoint) {
                this.el.classList.add(classPrefix + breakpoint);
            } else {
                this.el.classList.remove(classPrefix + breakpoint);
            }
        });
        }

        _isImagesOnly(questions) {
            var flag = (questions.length > 0);

            questions.forEach((question) => {
                if (question.image === null) {
                flag = false;
            }
        });

            return flag;
        }

        _renderForm(response) {
            var vote = response.vote;
            var isOnlyImages = this._isImagesOnly(vote.questions);

            var tmplQuestionItem = (question) => {
                var questionDesc;

                if (isOnlyImages) {
                    //noinspection CssUnknownTarget
                    questionDesc = new Template(`
                        <div class="tlum-vote__question-image"
                             title="<%- alt %>"
                             style="background-image: url(<%= src %>)"
                        ></div>
                    `).render(question.image);
                } else {
                    questionDesc = escapeHtml(question.text);
                }

                return new Template(`
                    <div class="tlum-vote__question-item">
                        <label class="tlum-vote__question-label">
                            <input class="tlum-vote__question-input" type="radio" name="tlum-vote-answer-<%- voteId %>[]" value="<%= questionId %>" required>
                            <%= questionDesc %>
                        </label>
                    </div>
                `).render({
                    voteId: vote.id,
                    questionId: question.id,
                    questionDesc: questionDesc,
                });
            };

            var tmplMainImage = () => {
                if (vote.image === null) {
                    return '';
                }

                return this.tmplMainImage.render(vote.image);
            };

            return new Template(`
                <form>
                    <div class="tlum-vote__form-container tlum-vote__type-<%= type %>">
                        <%= mainImage %>
                        <div class="tlum-vote__questions-container">
                            <%= questions %>
                        </div>
                    </div>
                    <div class="tlum-vote__footer">
                        <button type="submit" class="tlum-vote__btn tlum-vote__btn-red tlum-vote__btn-blue">Голосовать</button>
                    </div>
                </form>
            `).render({
                type: isOnlyImages ? 'image' : 'text',
                mainImage: tmplMainImage(),
                questions: vote.questions.map(tmplQuestionItem).join(''),
            });
        }

        _renderResult(response) {
            var vote = response.vote;
            var result = vote.result;
            var voted = response.voted;
            var isOnlyImages = result && this._isImagesOnly(result.items.map(item => item.question));

            var isVoted = (id) => {
                var flag = false;

                voted.forEach((question) => {
                    if (id === question.id) {
                    flag = true;
                    return false;
                }
            });

                return flag;
            };

            var tmplItem = (item) => {
                var tmplDesc;

                if (isOnlyImages) {
                    //noinspection CssUnknownTarget
                    tmplDesc = new Template(`
                        <div class="tlum-vote__result-image"
                             title="<%- title %>"
                             style="background-image: url(<%= src %>)"
                        ></div>
                    `).render({
                        title: item.question.text || item.question.image.alt,
                        src: item.question.image.src,
                    });
                } else {
                    tmplDesc = new Template(`
                        <div class="tlum-vote__result-text">
                            <%- text %>
                        </div>
                    `).render({text: item.question.text});
                }

                return new Template(`
                    <div class="tlum-vote__result-item <%= checked %>">
                        <div class="tlum-vote__check-mark <%= checked %>"></div>
                        <div class="tlum-vote__result-info">
                            ${tmplDesc}
                            <div class="tlum-vote__result-progress">
                                <div class="tlum-vote__result-progress-bar">
                                    <div class="tlum-vote__result-progress-bar-bottom"></div>
                                    <div class="tlum-vote__result-progress-bar-top"
                                         style="width: <%= width %>%;"
                                    ></div>
                                </div>
                                <div class="tlum-vote__result-progress-info">
                                    <span title="Кол-во голосов">
                                        <%= count %>
                                    </span>
                                    <span title="Процентное соотношение">
                                        (<%= persent %>%)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                `).render({
                    checked: isVoted(item.question.id) ? 'checked' : '',
                    width: item.persent,
                    count: item.count,
                    persent: Math.floor(item.persent),
                });
            };

            var content = (result === null) ?
                `<div class="tlum-vote__result-thank">
                    Спасибо за&nbsp;ваш голос, нам важно ваше&nbsp;мнение.
                </div>` :
                `<div class="tlum-vote__result-items">
                    ${result.items.map(tmplItem).join('')}
                </div>`;

            var tmplShare = (voted) => {
                var shareImage = (isOnlyImages && voted.length) ? voted.image : vote.image;
                var shareImageUrl = shareImage ? `${w.location.protocol}${this.options.host}${shareImage.src}` : null;
                var location = `${w.location.protocol}${this.options.host}` + '/vote/share/' + voted.id + '/';

                var share = {
                    location: new Url(location, { redirect: `${w.location.href}` }),
                    title: vote.text,
                    description: `Я принял участие в опросе «${vote.text}».`,
                    image: shareImageUrl,
                };

                return new Template(`
                    <div class="tlum-vote__social-container">
                        <div class="js-tlum-vote-share" data-services="facebook,vkontakte,odnoklassniki"
                            data-image="<%= image %>"
                            data-title="<%= title %>"
                            data-description="<%= description %>"
                            data-url="<%= location %>">
                        </div>
                    </div>
                `).render({
                    image: share.image,
                    title: share.title,
                    description: share.description,
                    location: share.location,
                });
            };

            var tmplMainImage = () => {
                if (vote.image === null) {
                    return '';
                }

                return this.tmplMainImage.render(vote.image);
            };

            return new Template(`
                <div class="tlum-vote__form-container tlum-vote__type-<%= type %>">
                    <%= mainImage %>
                    <div class="tlum-vote__result-container">
                        <%= content %>
                        <%= share %>
                    </div>
                </div>
            `).render({
                type: isOnlyImages ? 'image' : 'text',
                mainImage: tmplMainImage(),
                content: content,
                share: tmplShare(voted[0]),
            });
        }
    }

    /**
     * Public api.
     */
    w.TlumVote.renderMark = function (el) {
        new Vote({
            id: el.dataset.id,
            el: el,
            host: el.dataset.host,
            endpoint: el.dataset.endpoint,
        });

        el.parentNode.removeChild(el);
    };

    /**
     * Dequeue votes.
     */
    while (w.TlumVote.length) {
        let data = w.TlumVote.shift();

        try {
            new Vote(data);
        } catch (e) {
            console.log(e);
        }
    }
}(window, document));